import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Table from "react-bootstrap/Table"
import { FormattedMessage } from "react-intl"
import { injectIntl } from "react-intl"
import { useIntl } from "react-intl"
import ReactMarkdown from "react-markdown"

import Error from "./error"
import Loading from "./loading"

class WalletReceptionAddresses extends Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.state = { loaded: false, error: null, data: null }
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 60 * 1000)
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  refresh() {
    this.props.auth0
      .getAccessTokenSilently({
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        scope: process.env.GATSBY_AUTH0_SCOPE,
      })
      .then(accessToken => {
        const url = new URL(
          "/wallet/reception_addresses",
          process.env.GATSBY_API_URL
        )
        const params = {}
        Object.keys(params).forEach(key =>
          url.searchParams.append(key, params[key])
        )
        fetch(url, {
          method: "GET",
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(response => {
            if (response.ok) {
              response.json().then(json => {
                this.setState({ loaded: true, data: json })
              })
            } else {
              response.text().then(text => {
                this.setState({ loaded: true, error: text })
              })
            }
          })
          .catch(error => {
            this.setState({
              loaded: true,
              error: error,
            })
          })
      })
  }

  render() {
    const { intl } = this.props
    const { loaded, error, data } = this.state
    if (!loaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return (
        <Row>
          <Col className="col-auto">
            <List items={data} />
          </Col>
          <Col className="col-auto">
            <Alert variant="secondary" style={{ width: "18rem" }}>
              <ReactMarkdown
                children={intl.formatMessage({
                  id: "Wallet.Reception Addresses",
                })}
              />
            </Alert>
          </Col>
        </Row>
      )
    }
  }
}

function List(props) {
  const intl = useIntl()
  return (
    <Table striped bordered hover responsive>
      <thead className="thead-dark">
        <tr>
          <th>{intl.formatMessage({ id: "Currency" })}</th>
          <th>{intl.formatMessage({ id: "Address" })}</th>
          <th>{intl.formatMessage({ id: "Created" })}</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map(item => (
          <Item key={item.id} value={item} />
        ))}
      </tbody>
    </Table>
  )
}

function Item(props) {
  const value = props.value
  const intl = useIntl()
  return (
    <tr>
      <td className="text-center align-middle">{value.currency}</td>
      <td className="align-middle">
        {value.currency != "BCH" && value.currency != "BTG" && value.address }
        {value.currency == "BCH" && <del>{value.address}</del> }
        {value.currency == "BTG" && <del>{value.address}</del> }
      </td>
      <td className="text-center align-middle">
        {intl.formatDate(value.created)}
        <br />
        <small>{intl.formatTime(value.created)}</small>
      </td>
    </tr>
  )
}

export default withAuth0(injectIntl(WalletReceptionAddresses))
