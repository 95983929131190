import React from "react"

import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WalletNavigation from "../components/wallet_navigation"
import WalletReceptionAddresses from "../components/wallet_reception_addresses"

const WalletReceivePage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Receive" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <WalletNavigation location={location} />
      <WalletReceptionAddresses />
    </Layout>
  )
}

export default withAuthenticationRequired(WalletReceivePage)
